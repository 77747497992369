import { RefObject, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PathConstants } from '../../containers/App';
import { createContentInteractionEvent } from '../../plugins/analytics/events/ContentInteractionEvent';
import useAnalyticsDispatch from '../../plugins/analytics/useAnalyticsDispatch';
import useAnalyticsTimer from '../../plugins/analytics/useAnalyticsTimer';
import useUnauthenticatedUser from '../../plugins/analytics/useUnauthenticatedUser';
import VideoLookup from '../AssetLibrary';
import { VideoContext } from '../contexts/VideoContextProvider';
import useUnityEventHandler from './useUnityEventHandler';

export default function useQHMUnityEvents(element: RefObject<HTMLElement | null>, zone: string) {
  const history = useHistory();
  const dispatch = useAnalyticsDispatch();
  const { startVideo } = useContext(VideoContext);

  const [areaId, setAreaId] = useState<string | null>(null);
  const [contentId, setContentId] = useState<string | null>(null);

  const user = useUnauthenticatedUser();
  useAnalyticsTimer('Hotspot', areaId ? `${zone}-${areaId}` : null);
  useAnalyticsTimer('Content', contentId);

  const handleVideoOpen = (id: string) => {
    console.log(`Playing video [${id}]`);
    const content = VideoLookup[id];
    if (content) {
      dispatch.mutate(createContentInteractionEvent(user, 'video', id, zone));
      startVideo(content);
    }
  };

  const handleAreaEnter = (id: string) => {
    setAreaId(id);
  };
  const handleAreaLeave = (id: string) => {
    if (areaId === id) {
      setAreaId(null);
    }
    if (contentId) {
      setContentId(null);
    }
  };

  const handleContentInteraction = (type: string, id: string, source: string) => {
    dispatch.mutate(createContentInteractionEvent(user, type, id, source));
  };

  const handleSceneOpen = (scene: string) => {
    console.log(`Scene open ${scene}`);
    switch (scene) {
      case 'Zone1':
        history.push(PathConstants.Zone1);
        break;
      case 'Zone2A':
        history.push(PathConstants.Zone2a);
        break;
      case 'Zone2B':
        history.push(PathConstants.Zone2b);
        break;
      case 'Zone3':
        history.push(PathConstants.Zone3);
        break;

      default:
        console.log(`Unknown scene: ${scene}`);
    }
  };

  useUnityEventHandler(element, (event) => {
    const unityEvent = event.detail;

    switch (unityEvent.type) {
      case 'video':
        handleVideoOpen(unityEvent.content_id);
        break;
      case 'area-enter':
        handleAreaEnter(unityEvent.area_id);
        break;
      case 'area-leave':
        handleAreaLeave(unityEvent.area_id);
        break;
      case 'page-open':
        handleContentInteraction('page', unityEvent.content_id, unityEvent.area_id);
        setContentId(unityEvent.content_id);
        break;
      case 'url-pressed':
        handleContentInteraction('url', unityEvent.url, unityEvent.area_id);
        break;
      case 'scene-open':
        handleSceneOpen(unityEvent.scene);
        break;
      default:
        console.log(unityEvent);
    }
  });

  useEffect(() => {
    setAreaId(null);
    setContentId(null);
  }, [zone, setAreaId, setContentId]);
}
