import { Box } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { InfoOutlined } from '@material-ui/icons';
import LogoImage from '../components/LogoImage';

const MobilePage = (): JSX.Element => {
  if (!isMobile) {
    return <Redirect to="/" />;
  }

  return (
    <Box className="mobile">
      <LogoImage className="mobile__logo" />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            paddingX: 2,
          }}
        >
          <InfoOutlined />
          <h1>Mobile viewing not supported</h1>
        </Box>
        <p>
          This online museum experience is optimised for laptop and desktop devices. Please visit
          the site on these devices to explore the full immersive experience.
        </p>
      </Box>
    </Box>
  );
};

export default MobilePage;
