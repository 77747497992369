import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './sass/app.scss';
import VideoContextProvider from './utilities/contexts/VideoContextProvider';

// // Sentry initialisation
// if (process.env.REACT_APP_SENTRY_DSN) {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//           useEffect,
//           useLocation,
//           useNavigationType,
//           createRoutesFromChildren,
//           matchRoutes
//         ),
//       }),
//       new Replay(),
//     ],
//     tracesSampleRate: 1.0,
//   });
// }

// ReactGA.initialize(FRONTEND.GOOGLE_ANALYTICS);
// React Query initialisation
const queryClient = new QueryClient();

ReactDOM.render(
  // https://reactjs.org/docs/strict-mode.html
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <VideoContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </VideoContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
