export const rawLookup = {
  1: {
    // Video files
    10: '1-10 Graded w super_George Stein Zone1 Synagogue File 1 Sequence.mp4',
    12: '1-12 Graded w super_Ada Lipski Zone1 Synagogue File 1 Sequence.mp4',
    13: '1-13 Graded w super_Tereza Gubtmann Zone 1 Home File 2 Trimmed  Sequence.m4v',
    14: '1-14 Graded w super_Joseph Szabason Zone1 File 1 Sequence.mp4',
    15: '1-15 Graded w super_Francine Blanar Bergmann Zone1 Jewish Home Sequence.mp4',
    16: '1-16 Graded w super_Berthold Klug Zone1 School File 1 Sequence.mp4',
    17: '1-17 Graded w super_Tereza Pekarek Gutmann Zone1 School Sequence.mp4',
    18: '1-18 Graded w super_Gabrella Palan Zone1 Cafe File 1 Sequence.mp4',
    22: '1-22 Graded w super_Zelig Berkhut Zone1 Cafe File 1 Sequence.mp4',
    24: '1-24 Graded w super_Edgar Gold Zone1 Cafe Sequence.mp4',
    30: '1-30 - A-E.mp4',
    33: '1-33 Graded w super_Chaim Lipski Zone1 Synagogue File 1 Sequence.mp4',
    34: '1-34 Graded w super_Jonas Pampel Zone1 File 1 Sequence.mp4',
    35: '1-35 Graded w super_Eva Klug Zone1 Jewish Home Sequence.mp4',
    36: '1-36 Graded w super_Richard Werner Zone1 Synagogue File 1 Sequence.mp4',
    37: '1-37 Graded w super_Edith Dyzenhaus Zone1 Synagogue Sequence.mp4',
    38: '1-38 Graded w super_Michel Fried Zone1 File 1 Sequence.mp4',
    // Audio files
    20: "1-20 The_Klezmer's_freilach_-_jeremie.mp3",
    31: '1-31 VARIATIONEN FÜR ORCHESTER [VARIATIONS FOR ORCHESTRA] OP. 31 (1926–28) 6. Andante.ogg',
  },
  '2a': {
    16: '2a-16 Graded w super_Eva Klug Zone2A Annex Austria File 1 Sequence.mp4',
    17: '2a-17 Graded w super_Sue Goldzweig Zone2A Annex Austria Sequence.mp4',
    27: '2a-27 Graded w super_Uri Themel Zone2A AntiJewLaws File 1 Sequence.mp4',
    28: '2a-28 Graded w super_Berthold Klug Zone2A AntiJewLaws File 1 Sequence.mp4',
    37: '2a-37 Graded w super_Edgar Gold Zone2A Kristallnacht Sequence.mp4',
  },
  '2b': {
    '04': '2b-04 - CC - Graded w super_Joseph Szabazon Zone2B Resistance File 2 Sequence.mp4',
    '06': '2b-06 - CC - Graded w super_Sue Goldzweig Zone2b Kindertransport Sequence.mp4',
    '07': '2b-07 - CC - Graded w super_Francine Blanar Bergmann Zone2B WW2 Inv France File 4 Sequence.mp4',
    '08': '2b-08 - CC - Graded w super_Paulette Goldberg Zone2B WW2 CameForUs File 1 Sequence.mp4',
    13: '2b-13 - CC - Graded w super_Mike Staner Zone2B Ghettos File 1 Sequence.mp4',
    14: '2b-14 - CC - Graded w super_Chaim Lipski Zone2B Ghetto File 2 Sequence.mp4',
    15: '2b-15 - CC - Graded w super_Edith Dyzenhaus Zone2B Ghetto File 1 Sequence.mp4',
    17: '2b-17 - CC - Graded w super_Ada Lipski Zone2B Ghetto File 1 and 2 Sequence.mp4',
    20: '2b-20 - CC - Graded w super_Suzie Smeed Zone2B Ghettos Sequence.mp4',
    23: '2b-23 - RG-91.0085_0000_00_001.mp3',
    25: '2b-25 - CC - Graded w super_Berthold Klug Zone2B Resistance File 2 Sequence.mp4',
    26: '2b-26 - CC - Graded w super_Eva Klug Zone2B Resistance File 1 and 2 Sequence.mp4',
    28: '2b-28 - CC - Graded w super_Uri Themel Zone2B Resistance File 2 and 3 Sequence.mp4',
    29: '2b-29 - CC - Graded w super_Edgar Gold Zone2B Resistance Sequence.mp4',
    31: '2b-31 - CC - Graded w super_Peter Baruch Zone2B Resistance Sequence.mp4',
    32: '2b-32 - CC - Graded w super_Gabriella Palan Zone2B Resistance File 2 Sequence.mp4',
    33: '2b-33 - CC - Graded w super_Richard Werner Zone2B Resistance File 2 Sequence.mp4',
    34: '2b-34 - CC - Graded w super_Paulette Goldberg Zone2B Resistance File 2 Sequence.mp4',
    35: '2b-35 - CC - Graded w super_Jantje Voolstra Zone2B Resistance Sequence.mp4',
    40: '2b-40 - CC - Graded w super_Max Solway Zone2B Final Solution File 3 Sequence.mp4',
    45: '2b-45 - CC - Graded w super_Ada Lipski Zone2B Final Solution File 2 Sequence.mp4',
    46: '2b-46 - CC - Graded w super_Chaim Lipski Zone2B Final Solution File 2 Sequence.mp4',
    47: '2b-47 - CC - Graded w super_George Stein Zone2B Deportation File 2 Sequence.mp4',
    52: '2b-52 - CC - Graded w super_Jonas Pampel Zone2B Camps File 2 Sequence.mp4',
    '53a': '2b-53a - CC - Graded w super_George Stein Zone2B Camps File 3 and 4 Sequence.mp4',
    '53b': '2b-53b - CC - Graded w super_Chaim Lipski Zone2B Camps File 3 Sequence.mp4',
    54: '2b-54 - CC - Graded w super_John Lipski Zone2B Camps File 3 Sequence.mp4',
    58: '2b-58 - CC - Graded w super_George Stein Zone2B Camps File 3 and 4 Sequence.mp4',
    60: '2b-60 - CC - Graded w super_Mike Staner - Medical experiments.mp4',
    61: "2b-61 - Antonin_Dvorak_-_symphony_no._9_in_e_minor_'from_the_new_world',_op._95_-_ii._largo.ogg",
    62: '2b-62 - CC - Graded w super_Frank Rheichard Zone2B Camps Music File 3 Sequence.mp4',
    63: '2b-63 - CC - Graded w super_Jonas Pampel Zone2B Camps Music File 3 Sequence.mp4',
  },
  3: {
    '02': '3-02 - CC - Graded w super_Frank Rheichard Zone3 Death March File 1 Sequence.mp4',
    '03': '3-03 - CC - Graded w super_Jonas Pampel Zone3 Death March File 3 Sequence.mp4',
    '04': '3-04  - CC - Graded w super_Chaim Lipski Zone3 Death March File 4 Sequence.mp4',
    '05': '3-05 - Graded w super_Peter Kraus Zone3 Death March Sequence.mp4',
    10: '3-10 - CC - Graded w super_Ada Lipski Zone3 Liberation File 3 Sequence.mp4',
    11: '3-11 - CC - Graded w super_Eva Klug Zone3 Liberation File 4 Sequence.mp4',
    13: '3-13 - CC - Graded w super_Tereza Pekarek Zone3 Returning Home File 4 Sequence.mp4',
    14: '3-14 - CC - Graded w super_Uri Themel Zone3 Returning Home File 3 and 4 Sequence.mp4',
    15: '3-15 - CC - Graded w super_Berthold Klug Zone3 Returning Home File 3 Sequence.mp4',
    17: '3-17 - CC - Graded_w_super_Mike_Staner_Zone3_Facing_Loss_File_3_Sequence.mp4',
    18: '3-18 - CC - Graded_w_super_Francine_Blanar_Bergmann_Zone3_Facing_Loss_File_5_Sequence.mp4',
    19: '3-19 - CC - Graded_w_super_John_Lipski_Zone3_Reunion_File_3_Sequence.mp4',
    20: '3-20 - CC - Graded w super_Suzie Smeed Zone3 Reunion Sequence.mp4',
    21: '3-21 - CC - Graded_w_super_Michel_Fried_Zone3_Reunion_File_4_Sequence.mp4',
    22: '3-22 - CC - Graded w super_Berthold Klug Zone3 Reunion File 4 Sequence.mp4',
    28: '3-28 - CC - Graded_w_super_George_Stein_Zone3_Dps_Camps_File_4_Sequence.mp4',
    29: '3-29 - CC - Graded w super_Chaim Lipski Zone3 DPs Camps File 5 Sequence.m4v',
    32: '3-32 - CC - Graded_w_super_Edith_Dyzenhaus_Zone3_Postwar_Emigration_File_4_Sequence.mp4',
    44: '3-44 - CC - Graded_w_super_Francine_Blanar_Bergmann_Zone3_Rebuilding_Life_SA_File_5_Sequence.mp4',
    59: '3-59 - CC - Graded w super_Berthold Klug Zone3 Survivor Message File 4 Sequence.mp4',
    60: '3-60 - CC - Graded_w_super_Michel_Fried_Zone3_Survivor_Message_File_4_Sequence.mp4',
    61: '3-61 - CC - Graded w super_Eva Klug Zone3 Survivor Message File 4 Sequence.mp4',
    62: '3-62 - CC - Graded w super_Gabriella Palan Zone3 Survivor Message File 3 Sequence.mp4',
    63: '3-63 - CC - Graded_w_super_Francine_Blanar_Bergmann_Zone3_Survivor_Message_File_6_Sequence.mp4',
    64: '3-64 - CC - Graded w super_Mike Staner Zone3 Survivor Message File 3 Sequence.mp4',
    68: '3-68 - CC - Graded_w_super_Paulette_Goldberg_Zone3_Memorial_File_1_Sequence.mp4',
    73: '3-73 - CC - Graded w super_Suzie Smeed Zone3 Memorial Sequence.mp4',
    76: '3-76 - CC - Graded w super_Jantje Voolstra - survivor message.mp4',
  },
  // The Agency's non-content videos
  qhm: {
    1: 'video_1.m4v',
    '1s': 'video_1_short.mp4',
    2: 'video_2.mp4',
    '2as': 'video_2a_short.mp4',
    '2bs': 'video_2b_short.mp4',
    3: 'video_3.mp4',
    '3s': 'video_3_short.mp4',
    fire: 'Reichstag Fire.m4v',
  },
};

export const VideoLookup: Record<string, string> = Object.entries(rawLookup)
  .flatMap(([zone, mapping]) =>
    Object.entries(mapping).map(([item, file]) => [
      `${zone}-${item}`,
      `/StreamingAssets/content/${file}`,
    ]),
  )
  .reduce((acc, [id, file]) => ({ ...acc, [id]: file }), {});

export default VideoLookup;
