import React from 'react';

export interface LogoImageProps {
  className?: string;
}

// This is a JSX version of QHM-Logo-tight.svg converted using https://svg2jsx.com/
// Which is itself a cropped version of QHM-Logo.svg to remove excess whitespace
const LogoImage = (props: LogoImageProps): JSX.Element => {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="660"
      height="165.9"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 660 165.9"
      xmlSpace="preserve"
      className={className}
    >
      {/* Title added for accessibility */}
      <title>Queensland Holocaust Museum</title>
      <g transform="translate(-91 -75.2)">
        <path d="M133.2 157.8c-3.4 0-6.2-.9-8.3-2.7-2.1-1.7-3.7-4.3-4.8-7.8l-2.4.3c-.8.1-1.6.2-2.5.2-7.8 0-13.8-2.9-18-8.6-4.1-5.7-6.2-13.8-6.2-24.1V109c0-5 .5-9.6 1.5-13.6 1.1-4.1 2.6-7.5 4.7-10.3 2.1-2.9 4.7-5 7.7-6.5s6.4-2.3 10.3-2.3c3.9 0 7.3.7 10.3 2.2 3.1 1.5 5.6 3.6 7.6 6.4 2 2.8 3.6 6.3 4.6 10.3 1.1 4 1.6 8.6 1.6 13.8v6.1c0 6.3-.8 11.7-2.3 16.4-1.5 4.6-3.8 8.3-6.9 11 .5 2.4 1.1 4.2 1.7 5.3.7 1.1 1.6 1.7 2.7 1.7 1.3 0 2.2-.6 2.7-1.8.5-1.1.8-2.8 1-5.1l6.9.7c-.1 2.2-.3 4.1-.7 5.9-.4 1.8-1.1 3.3-2 4.6-.9 1.3-2.1 2.4-3.5 3.1-1.5.6-3.4.9-5.7.9zm-27.4-42.6c0 5.4.3 9.8.9 13.3.5-2.1 1.6-3.8 3.3-5.2 1.7-1.3 3.9-2 6.6-2 3.1 0 5.7 1 7.8 3.1.1-1.5.2-3.1.3-4.9.1-1.8.1-3.7.1-5.8v-6.3c0-7.1-.8-12.3-2.4-15.6-1.6-3.3-4-4.9-7.1-4.9-3.1 0-5.5 1.7-7.1 5-1.6 3.3-2.4 8.5-2.4 15.5zm10.2 23.4c.9 0 1.7-.1 2.4-.4-.5-2.9-1.1-5.1-1.7-6.5-.6-1.5-1.7-2.2-3.2-2.2-1.1 0-2 .4-2.7 1.1-.6.7-.9 1.6-.9 2.7 0 1.5.5 2.8 1.5 3.8 1.1 1 2.6 1.5 4.6 1.5z" />
        <path d="M158.1 147.2c-3.7 0-6.5-1.1-8.3-3.3-1.8-2.3-2.7-5.2-2.7-8.6V95.4h13v37.4c0 1.5.3 2.7.9 3.6.6.8 1.6 1.2 3.2 1.2 1.7 0 3.2-1.1 4.4-3.3 1.2-2.3 1.8-5.3 1.8-8.9v-30h12.9v50.8h-11.6l-.7-9.2h-.3c-1.7 4-3.6 6.7-5.7 8.1-2.2 1.4-4.5 2.1-6.9 2.1z" />
        <path d="M208.7 103.2c-2 0-3.4 1.1-4.2 3.3-.8 2.2-1.2 5.4-1.2 9.8h10.5c0-4.8-.4-8.2-1.2-10.1-.9-2.1-2.2-3-3.9-3zm17.7 27.8c-.4 2.3-1 4.4-1.8 6.4s-1.9 3.7-3.3 5.2c-1.4 1.4-3.1 2.5-5.1 3.3-1.9.8-4.2 1.2-6.9 1.2-2.9 0-5.6-.5-8-1.5-2.4-1.1-4.5-2.6-6.3-4.7-1.7-2-3.1-4.6-4-7.6-1-3.1-1.4-6.6-1.4-10.5v-4.2c0-3.3.4-6.4 1.2-9.4.8-3 2-5.6 3.6-7.7 1.7-2.2 3.7-3.9 6-5.2 2.4-1.3 5.1-1.9 8.2-1.9 5.5 0 9.9 2.1 13.1 6.2 3.3 4.1 4.9 10.5 4.9 19.1v2.8h-23.4v.3c0 5.1.5 9 1.5 11.6 1.1 2.6 3 3.9 5.7 3.9 2.2 0 3.9-.9 4.9-2.7 1.1-1.9 1.9-4.4 2.3-7.6z" />
        <path d="M250.6 103.2c-2 0-3.4 1.1-4.2 3.3-.8 2.2-1.2 5.4-1.2 9.8h10.5c0-4.8-.4-8.2-1.2-10.1-.8-2.1-2.1-3-3.9-3zm17.8 27.8c-.4 2.3-1 4.4-1.8 6.4s-1.9 3.7-3.3 5.2c-1.4 1.4-3.1 2.5-5.1 3.3-1.9.8-4.2 1.2-6.9 1.2-2.9 0-5.6-.5-8-1.5-2.4-1.1-4.5-2.6-6.3-4.7-1.7-2-3.1-4.6-4-7.6-1-3.1-1.4-6.6-1.4-10.5v-4.2c0-3.3.4-6.4 1.2-9.4.8-3 2-5.6 3.6-7.7 1.7-2.2 3.7-3.9 6-5.2 2.4-1.3 5.1-1.9 8.2-1.9 5.5 0 9.9 2.1 13.1 6.2 3.3 4.1 4.9 10.5 4.9 19.1v2.8h-23.4v.3c0 5.1.5 9 1.5 11.6 1.1 2.6 3 3.9 5.7 3.9 2.2 0 3.9-.9 4.9-2.7 1.1-1.9 1.8-4.4 2.3-7.6z" />
        <path d="M274.7 95.4h11.9l.5 9.6h.2c1.5-3.6 3.5-6.3 6-7.9 2.6-1.7 5.1-2.6 7.7-2.6 3.6 0 6.3 1.1 7.8 3.3 1.6 2.2 2.4 4.9 2.4 8.2v40.3h-12.9V109c0-1.7-.3-3-.9-3.7-.6-.8-1.5-1.2-2.9-1.2-1.9 0-3.5 1.1-4.9 3.2-1.3 2-1.9 4.7-1.9 7.9v31.2h-13z" />
        <path d="M332.9 147.2c-2.7 0-5.1-.3-7.1-1-1.9-.7-3.6-1.6-5-2.8-1.4-1.2-2.6-2.6-3.4-4.3-.9-1.7-1.6-3.5-2.2-5.5l8.5-3.3c.9 3.6 2.1 6 3.5 7.4 1.5 1.3 3.3 1.9 5.4 1.9 1.8 0 3.1-.5 4-1.4.9-1 1.3-2.2 1.3-3.6 0-.8-.1-1.6-.4-2.2-.2-.6-.6-1.2-1.1-1.8-.6-.6-1.4-1.1-2.5-1.7-1-.6-2.3-1.3-3.9-2.1-4-1.9-7.2-4.1-9.5-6.7-2.3-2.6-3.4-6.1-3.4-10.5 0-2.3.4-4.4 1.1-6.2.8-1.8 1.8-3.4 3.3-4.7 1.5-1.3 3.2-2.4 5.2-3.1 2-.7 4.3-1.1 6.8-1.1s4.6.4 6.4 1.1c1.9.6 3.4 1.5 4.6 2.7 1.3 1.1 2.3 2.5 3 4.1.7 1.6 1.2 3.3 1.5 5.3l-8.2 2.5c-.6-3.1-1.6-5.1-2.8-6.2-1.2-1.1-2.7-1.7-4.6-1.7-1.3 0-2.4.4-3.4 1.2-1 .8-1.4 2.1-1.4 3.7 0 .9.1 1.7.4 2.4.3.6.7 1.2 1.2 1.8.6.6 1.3 1.1 2.3 1.7 1 .6 2.3 1.2 3.7 1.9 2.2 1.1 4.2 2.1 5.9 3.1 1.7 1 3.2 2 4.3 3.3 1.1 1.1 2 2.5 2.6 4.1.6 1.5 1 3.5 1 5.8 0 5.1-1.5 9.1-4.5 11.9-3 2.6-7.2 4-12.6 4z" />
        <path d="M355.2 75.2h13v71h-13z" />
        <path d="M384.9 147.2c-3.4 0-6.1-1.1-8-3.2-1.9-2.2-2.9-5.4-2.9-9.6 0-2.3.3-4.2 1-5.8.7-1.6 1.6-3 2.7-4.1 1.1-1.1 2.3-2.1 3.5-2.9 1.3-.8 2.6-1.6 3.9-2.3 1.8-1.1 3.6-2 5.4-2.9 1.8-.9 3.3-1.7 4.7-2.4v-5.2c0-4-1.5-5.9-4.6-5.9-1.8 0-3.2.8-4 2.3-.8 1.5-1.4 3.7-1.7 6.6l-9.7-1.1c.3-5.5 2-9.6 5.2-12.2 3.2-2.7 7.2-4 12.1-4 5.4 0 9.4 1.2 11.9 3.5 2.6 2.4 3.8 6 3.8 11v24.1c0 2.8.4 4.6 1.3 5.3.4.4.8.6 1.3.8.6.1 1.2.2 1.8.2v6.4c-.3.2-.6.4-1.1.6-.5.2-1.1.4-1.7.5l-1.8.3c-.6.1-1.2.1-1.7.1-2.7 0-4.9-.7-6.6-2.2-1.7-1.5-2.6-3.9-2.8-7.4h-.5c-2.1 6.3-5.9 9.5-11.5 9.5zm5.4-8.2c1.7 0 2.9-.8 3.6-2.4.8-1.7 1.1-3.6 1.1-5.9v-9l-2.1 1.2c-.6.4-1.5.9-2.7 1.6-1.3.9-2.3 1.9-3 3.1-.7 1.1-1.1 2.9-1.1 5.4 0 2.2.3 3.7 1 4.7.8.8 1.9 1.3 3.2 1.3z" />
        <path d="M417.6 95.4h11.9l.5 9.6h.2c1.5-3.6 3.5-6.3 6-7.9 2.6-1.7 5.1-2.6 7.7-2.6 3.6 0 6.3 1.1 7.8 3.3 1.6 2.2 2.4 4.9 2.4 8.2v40.3h-12.9V109c0-1.7-.3-3-.9-3.7-.6-.8-1.5-1.2-2.9-1.2-1.9 0-3.5 1.1-4.9 3.2-1.3 2-1.9 4.7-1.9 7.9v31.2h-13z" />
        <path d="M479.9 138.8c1.9 0 3.4-1.2 4.4-3.6 1.1-2.5 1.6-6.2 1.6-11.2v-6.1c0-5.3-.6-9.1-1.7-11.3-1.1-2.2-2.6-3.3-4.4-3.3-1.8 0-3.3 1.1-4.4 3.3-1 2.2-1.5 6-1.5 11.4v5.8c0 4.8.5 8.6 1.4 11.2 1 2.5 2.6 3.8 4.6 3.8zm-4 8.4c-2.6 0-4.9-.6-6.9-1.7-2-1.2-3.6-2.9-5-5.1-1.3-2.2-2.2-4.7-2.9-7.7-.6-3-1-6.3-1-10v-4.1c0-3.9.4-7.3 1.2-10.3.8-3 1.9-5.5 3.3-7.6 1.4-2 3-3.6 4.9-4.7 1.9-1.1 4-1.6 6.2-1.6 2.6 0 4.7.7 6.3 2.1 1.7 1.4 3 3.2 3.9 5.5h.4V75.2H499v71.5h-8.7l-2.7-8.3h-.4c-.8 2.3-2.2 4.3-4.2 6.1-1.7 1.8-4.2 2.7-7.1 2.7z" />
        <path d="M93.4 171.5h13.8v28.1h15.2v-28.1h13.8V240h-13.8v-29.5h-15.2V240H93.4z" />
        <path d="M182.1 216.6c0 3.3-.4 6.5-1.1 9.5-.8 2.9-1.9 5.5-3.5 7.7s-3.6 3.9-6.1 5.3c-2.4 1.3-5.4 1.9-8.8 1.9-3.3 0-6.2-.6-8.6-1.9-2.4-1.3-4.4-3.1-6-5.3-1.6-2.2-2.8-4.8-3.5-7.7-.8-3-1.1-6.2-1.1-9.5v-4c0-3.3.4-6.4 1.1-9.4.8-3 1.9-5.6 3.5-7.7 1.6-2.2 3.6-4 6-5.3 2.5-1.3 5.4-1.9 8.8-1.9 3.4 0 6.3.6 8.7 1.9 2.5 1.3 4.5 3 6 5.3 1.6 2.2 2.8 4.8 3.5 7.7.8 2.9 1.1 6.1 1.1 9.4zm-25.2 3.2c0 8.3 1.9 12.4 5.8 12.4 2 0 3.5-1 4.4-3.1.9-2 1.3-5.2 1.3-9.4v-10.3c0-4.3-.4-7.4-1.3-9.5-.9-2-2.4-3.1-4.4-3.1-3.9 0-5.8 4.2-5.8 12.5z" />
        <path d="M188.3 169h13v71h-13z" />
        <path d="M246.5 216.6c0 3.3-.4 6.5-1.1 9.5-.8 2.9-1.9 5.5-3.5 7.7s-3.6 3.9-6.1 5.3c-2.4 1.3-5.4 1.9-8.8 1.9-3.3 0-6.2-.6-8.6-1.9-2.4-1.3-4.4-3.1-6-5.3-1.6-2.2-2.8-4.8-3.5-7.7-.8-3-1.1-6.2-1.1-9.5v-4c0-3.3.4-6.4 1.1-9.4.8-3 1.9-5.6 3.5-7.7 1.6-2.2 3.6-4 6-5.3 2.5-1.3 5.4-1.9 8.8-1.9 3.4 0 6.3.6 8.7 1.9 2.5 1.3 4.5 3 6 5.3 1.6 2.2 2.8 4.8 3.5 7.7.8 2.9 1.1 6.1 1.1 9.4zm-25.3 3.2c0 8.3 1.9 12.4 5.8 12.4 2 0 3.5-1 4.4-3.1.9-2 1.3-5.2 1.3-9.4v-10.3c0-4.3-.4-7.4-1.3-9.5-.9-2.1-2.4-3.1-4.4-3.1-3.9 0-5.8 4.2-5.8 12.5z" />
        <path d="M270.4 241c-2.7 0-5.2-.5-7.6-1.5-2.3-1.1-4.3-2.6-6-4.7-1.7-2-3-4.6-3.9-7.7-1-3.1-1.4-6.6-1.4-10.5v-4c0-3.9.5-7.3 1.4-10.3 1-3.1 2.4-5.6 4.1-7.7 1.8-2 3.9-3.6 6.3-4.7s5.1-1.6 7.9-1.6c10 0 15.4 5.4 16.3 16.2l-9.5 2.9c-.1-3.6-.7-6.3-1.7-7.8-1-1.6-2.5-2.4-4.5-2.4-2.3 0-4 1.2-5.1 3.5-1.1 2.3-1.6 5.9-1.6 10.8v6.3c0 5 .6 8.7 1.7 11 1.2 2.3 2.8 3.4 4.9 3.4 2.3 0 3.9-1 4.9-2.9 1-1.9 1.6-4.6 1.9-8.1l9.3 2.9c-.6 5.2-2.3 9.3-5.1 12.3s-6.9 4.6-12.3 4.6z" />
        <path d="M302.6 241c-3.4 0-6.1-1.1-8-3.2-1.9-2.2-2.9-5.4-2.9-9.6 0-2.3.3-4.2 1-5.8.7-1.6 1.6-3 2.7-4.1 1.1-1.1 2.3-2.1 3.5-2.9 1.3-.8 2.6-1.6 3.9-2.3 1.8-1.1 3.6-2 5.4-2.9 1.8-.9 3.3-1.7 4.7-2.4v-5.2c0-4-1.5-5.9-4.6-5.9-1.8 0-3.2.8-4 2.3-.8 1.5-1.4 3.7-1.7 6.6l-9.7-1.1c.3-5.5 2-9.6 5.2-12.2 3.2-2.7 7.2-4 12.1-4 5.4 0 9.4 1.2 11.9 3.5 2.6 2.4 3.8 6 3.8 11v24.1c0 2.8.4 4.6 1.3 5.3.4.4.8.6 1.3.8.6.1 1.2.2 1.8.2v6.4c-.3.2-.6.4-1.1.6-.5.2-1.1.4-1.7.5l-1.8.3c-.6.1-1.2.1-1.7.1-2.7 0-4.9-.7-6.6-2.2-1.7-1.5-2.6-3.9-2.8-7.4h-.6c-2 6.3-5.8 9.5-11.4 9.5zm5.5-8.2c1.7 0 2.9-.8 3.6-2.4.8-1.7 1.1-3.6 1.1-5.9v-9l-2.1 1.2c-.6.4-1.5.9-2.7 1.6-1.3.9-2.3 1.9-3 3.1-.7 1.1-1.1 2.9-1.1 5.4 0 2.2.3 3.7 1 4.7.8.8 1.8 1.3 3.2 1.3z" />
        <path d="M346.2 241c-3.7 0-6.5-1.1-8.3-3.3-1.8-2.3-2.7-5.2-2.7-8.6v-39.8h13v37.4c0 1.5.3 2.7.9 3.6.6.8 1.6 1.2 3.2 1.2 1.7 0 3.2-1.1 4.4-3.3 1.2-2.3 1.8-5.3 1.8-8.9v-30h12.9V240h-11.6l-.7-9.3h-.3c-1.7 4-3.6 6.7-5.7 8.1-2.2 1.5-4.5 2.2-6.9 2.2z" />
        <path d="M393.3 241c-2.7 0-5.1-.3-7.1-1-1.9-.7-3.6-1.6-5-2.8-1.4-1.2-2.6-2.6-3.4-4.3-.9-1.7-1.6-3.5-2.2-5.5l8.5-3.3c.9 3.6 2.1 6 3.5 7.4 1.5 1.3 3.3 1.9 5.4 1.9 1.8 0 3.1-.5 4-1.4.9-1 1.3-2.2 1.3-3.6 0-.8-.1-1.6-.4-2.2-.2-.6-.6-1.2-1.1-1.8-.6-.6-1.4-1.1-2.5-1.7-1-.6-2.3-1.3-3.9-2.1-4-1.8-7.2-4.1-9.5-6.7-2.3-2.6-3.4-6.1-3.4-10.5 0-2.3.4-4.4 1.1-6.2.8-1.8 1.8-3.4 3.3-4.7 1.5-1.3 3.2-2.4 5.2-3.1 2-.7 4.3-1.1 6.8-1.1s4.6.4 6.4 1.1c1.9.6 3.4 1.5 4.6 2.7 1.3 1.1 2.3 2.5 3 4.1.7 1.6 1.2 3.3 1.5 5.3l-8.2 2.5c-.6-3.1-1.6-5.1-2.8-6.2-1.2-1.1-2.7-1.7-4.6-1.7-1.3 0-2.4.4-3.4 1.2-1 .8-1.4 2.1-1.4 3.7 0 .9.1 1.7.4 2.4.3.6.7 1.2 1.2 1.8.6.6 1.3 1.1 2.3 1.7 1 .6 2.3 1.2 3.7 1.9 2.2 1.1 4.2 2.1 5.9 3.1 1.7 1 3.2 2 4.3 3.3 1.1 1.1 2 2.5 2.6 4.1.6 1.5 1 3.5 1 5.8 0 5.1-1.5 9.1-4.5 11.9-3.1 2.6-7.3 4-12.6 4z" />
        <path d="M431.7 240c-2 0-3.7-.1-5.1-.3-1.3-.3-2.5-.6-3.4-1.1-1.5-.7-2.8-1.8-3.7-3.3-.9-1.5-1.3-3.9-1.3-7v-29.8h-5.5v-9.3h5.5v-14.7H431v14.7h8v9.3h-8v26.4c0 2.4.6 4 1.9 4.7.8.6 2 .9 3.6.9h2.9v9.6h-7.7z" />
        <path d="M470.2 240h-11.3v-68.5h20.9l7.6 33.3 2.1 10.4h.3l2.1-10.4 7.5-33.3h19.9V240h-12.8v-52.4h-.5l-2.7 11.5-10 40.9h-10.5l-9.7-40.9-2.6-11.5h-.3z" />
        <path d="M538.3 241c-3.7 0-6.5-1.1-8.3-3.3-1.8-2.3-2.7-5.2-2.7-8.6v-39.8h13v37.4c0 1.5.3 2.7.9 3.6.6.8 1.6 1.2 3.2 1.2 1.7 0 3.2-1.1 4.4-3.3 1.2-2.3 1.8-5.3 1.8-8.9v-30h12.9V240h-11.6l-.7-9.3h-.3c-1.7 4-3.6 6.7-5.7 8.1-2.2 1.5-4.5 2.2-6.9 2.2z" />
        <path d="M585.4 241c-2.7 0-5.1-.3-7.1-1-1.9-.7-3.6-1.6-5-2.8-1.4-1.2-2.6-2.6-3.4-4.3-.9-1.7-1.6-3.5-2.2-5.5l8.5-3.3c.9 3.6 2.1 6 3.5 7.4 1.5 1.3 3.3 1.9 5.4 1.9 1.8 0 3.1-.5 4-1.4.9-1 1.3-2.2 1.3-3.6 0-.8-.1-1.6-.4-2.2-.2-.6-.6-1.2-1.2-1.8-.6-.6-1.4-1.1-2.5-1.7-1-.6-2.3-1.3-3.9-2.1-4-1.8-7.2-4.1-9.5-6.7-2.3-2.6-3.4-6.1-3.4-10.5 0-2.3.4-4.4 1.1-6.2.8-1.8 1.9-3.4 3.3-4.7 1.5-1.3 3.2-2.4 5.2-3.1 2-.7 4.3-1.1 6.8-1.1s4.6.4 6.4 1.1c1.9.6 3.4 1.5 4.6 2.7 1.3 1.1 2.3 2.5 3 4.1.7 1.6 1.2 3.3 1.5 5.3l-8.2 2.5c-.6-3.1-1.6-5.1-2.8-6.2-1.2-1.1-2.7-1.7-4.6-1.7-1.3 0-2.4.4-3.4 1.2-1 .8-1.4 2.1-1.4 3.7 0 .9.1 1.7.4 2.4.3.6.7 1.2 1.2 1.8.6.6 1.3 1.1 2.3 1.7 1 .6 2.3 1.2 3.7 1.9 2.2 1.1 4.2 2.1 5.9 3.1 1.7 1 3.2 2 4.3 3.3 1.1 1.1 2 2.5 2.6 4.1.6 1.5 1 3.5 1 5.8 0 5.1-1.5 9.1-4.5 11.9-3 2.6-7.2 4-12.5 4z" />
        <path d="M625.6 197c-2 0-3.4 1.1-4.2 3.3-.8 2.2-1.2 5.4-1.2 9.8h10.5c0-4.8-.4-8.2-1.2-10.1-.9-2.1-2.2-3-3.9-3zm17.8 27.8c-.4 2.3-1 4.4-1.8 6.4s-1.9 3.7-3.4 5.2c-1.4 1.4-3.1 2.5-5.1 3.3-1.9.8-4.2 1.2-6.9 1.2-2.9 0-5.6-.5-8-1.5-2.4-1.1-4.5-2.6-6.3-4.7-1.7-2-3.1-4.6-4-7.6-1-3.1-1.4-6.6-1.4-10.5v-4.2c0-3.3.4-6.4 1.2-9.4.8-3 2-5.6 3.6-7.7 1.7-2.2 3.7-3.9 6-5.2 2.4-1.3 5.1-1.9 8.2-1.9 5.5 0 9.9 2.1 13.1 6.2 3.3 4.1 4.9 10.5 4.9 19.1v2.8h-23.4v.3c0 5.1.5 9 1.5 11.6 1.1 2.6 3 3.9 5.7 3.9 2.2 0 3.9-.9 4.9-2.7 1.1-1.9 1.9-4.4 2.3-7.6z" />
        <path d="M660.4 241c-3.7 0-6.5-1.1-8.3-3.3-1.8-2.3-2.7-5.2-2.7-8.6v-39.8h13v37.4c0 1.5.3 2.7.9 3.6.6.8 1.6 1.2 3.2 1.2 1.7 0 3.2-1.1 4.4-3.3 1.2-2.3 1.8-5.3 1.8-8.9v-30h12.9V240H674l-.7-9.3h-.3c-1.7 4-3.6 6.7-5.7 8.1-2.2 1.5-4.5 2.2-6.9 2.2z" />
        <path d="M706.1 240h-13v-50.8H705l.5 9.6h.2c1.7-3.8 3.7-6.4 6-8 2.4-1.7 4.8-2.5 7.2-2.5 3.2 0 5.5 1 7.1 2.9 1.6 1.9 2.5 4.5 2.6 7.7h.1c.7-1.9 1.5-3.5 2.5-4.8 1-1.3 2.1-2.4 3.2-3.3 1.1-.9 2.3-1.5 3.4-1.9 1.2-.4 2.4-.6 3.5-.6 3.4 0 5.8 1.1 7.4 3.2 1.5 2 2.3 4.8 2.3 8.1V240h-12.9v-38c0-2.9-1.1-4.3-3.2-4.3-1.8 0-3.3 1.2-4.5 3.7-1.2 2.5-1.7 5.7-1.7 9.8l-.1-.9V240h-13v-38c0-2.9-1-4.3-3.1-4.3-1.8 0-3.4 1.1-4.6 3.2-1.2 2-1.7 4.7-1.7 7.9V240z" />
      </g>
    </svg>
  );
};

export default LogoImage;
