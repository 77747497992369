import React, { useContext } from 'react';
import VideoLookup from '../utilities/AssetLibrary';
import { VideoContext } from '../utilities/contexts/VideoContextProvider';
import ZoneTemplate from './ZoneTemplate';

const Zone3 = (): JSX.Element => {
  const { startVideo } = useContext(VideoContext);

  const handleStart = () => {
    startVideo(VideoLookup['qhm-3']);
  };

  return (
    <ZoneTemplate
      key="zone-3"
      className="zone-3"
      buildUrl="Build/Zone3"
      title="After the Holocaust"
      videoUrl={VideoLookup['qhm-3s']}
      disclaimer={
        <p>
          In this area of the virtual experience, there are references to sexual violence. This
          material can sometimes be confronting and disturbing. Sometimes words or images can cause
          sadness or distress, or trigger traumatic memories for people, particularly survivors of
          past abuse, violence or childhood trauma. For some people, these responses can be
          overwhelming. If you need to talk to someone, support is available through your
          community’s health care and social services network. You can find them in your town or
          city through a web search.
        </p>
      }
      onStart={handleStart}
    >
      <p>
        From late 1944, as Allied forces &ndash; the British, American and Russian (Soviet) troops
        &ndash; advanced from the east and the west, liberating concentration, labour and death
        camps across Europe. Nazi Germany’s defeat was fast-approaching. However, as the Nazi’s
        ideal of the Third Reich was lost, the pace of their genocidal plans accelerated. Those who
        remained in ghettos or labour camps were deported to operational death camps, often on
        forced evacuations by foot (death marches).
      </p>
      <p>
        The official surrender of Germany was announced on 8 May 1945, marking the end of the war in
        Europe. The Holocaust had taken the lives of 6 million European Jews, including 1.5 million
        Jewish children. Another 5 million people &ndash; people with disabilities, Roma and Sinti
        communities, gay men, Polish people and Soviet prisoners of war (POWs), political opponents
        and Jehovah’s Witnesses &ndash; were murdered by the Nazi regime.
      </p>
      <p>
        Some Jewish people survived the Holocaust by spending years in resistance groups, or
        narrowly avoiding deportation by going into hiding. Others managed to stay alive despite the
        living conditions of the Nazi ghettos and camps and the brutality of the Nazi authorities.
      </p>
      <p>
        For survivors, in the immediate aftermath of WW2, they had to come to terms with the loss of
        their families, friends and their communities. When attempting to return to the places they
        once lived, many survivors faced a hostile reception, often from former neighbours or
        strangers who were now living in their homes. There were others who chose not to return to
        their homes, afraid of what they would find or knowing that there was nothing left to return
        to. With no place to go, tens of thousands of Holocaust survivors headed to refugee centres
        and displaced persons (DP camps) in Europe. From here, they sought opportunities to emigrate
        from Europe and rebuild their lives.
      </p>
    </ZoneTemplate>
  );
};

export default Zone3;
