import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import VideoModal from '../../components/VideoModal';

export interface IVideoContext {
  src: string | null;
  startVideo: (
    src: string,
    options?: {
      startTime?: number;
    },
  ) => void;
  stopVideo: () => void;
}

export const VideoContext = createContext<IVideoContext>({
  src: '',
  startVideo: () => {},
  stopVideo: () => {},
});

export interface VideoContextProviderProps {
  children?: ReactNode | ReactNode[];
}

const VideoContextProvider = (props: VideoContextProviderProps): JSX.Element => {
  const { children } = props;

  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<number>();

  useEffect(() => {
    // console.log('loadedmetadata effect', {
    //   ref: videoRef,
    //   startTime,
    // });
    const callback = () => {
      // console.log('loadedmetadata callback', {
      //   ref: videoRef,
      //   startTime,
      // });
      if (videoRef && startTime) {
        videoRef.currentTime = startTime;
      }
    };

    videoRef?.addEventListener('loadedmetadata', callback);

    return () => {
      videoRef?.removeEventListener('loadedmetadata', callback);
    };
  });

  const handelVideoStart = useCallback(
    (
      src: string,
      options?: {
        startTime?: number;
      },
    ) => {
      setVideoSrc(src);
      setStartTime(options?.startTime);
    },
    [setVideoSrc, setStartTime],
  );

  const handleVideoStop = useCallback(() => {
    setVideoSrc(null);
  }, [setVideoSrc]);

  const handleRefSet = (element: HTMLVideoElement) => {
    setVideoRef(element);
  };

  return (
    <VideoContext.Provider
      value={useMemo(
        () => ({
          src: videoSrc,
          startVideo: handelVideoStart,
          stopVideo: handleVideoStop,
        }),
        [videoSrc, handelVideoStart, handleVideoStop],
      )}
    >
      {children}
      <VideoModal src={videoSrc} onClose={handleVideoStop} ref={handleRefSet} />
    </VideoContext.Provider>
  );
};

export default VideoContextProvider;
