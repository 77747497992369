import React, { HTMLProps } from 'react';
import { Colour } from '../utilities/DesignConstants';
import toClassString from '../utilities/functions/toClassString';

export interface SectionProps extends HTMLProps<HTMLDivElement> {
  colour?: Colour;
  dark?: boolean;
  centered?: boolean;
  contentClass?: string;
}

const Section = (props: SectionProps): JSX.Element => {
  const { colour, dark, centered, contentClass, className, children, ...others } = props;

  return (
    <div
      className={toClassString(
        'section',
        colour ? `section--${colour}` : null,
        `section--${dark ? 'dark' : 'light'}`,
        className,
      )}
      {...others}
    >
      <div
        className={toClassString(
          'section__content',
          centered ? 'section__content--centered' : null,
          contentClass,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
