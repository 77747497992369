import AnalyticsEventBase, { AnalyticsUser } from './AnalyticsEventBase';

export const ContentInteractionEventName = 'ContentInteraction';

export interface ContentInteractionEventData {
  type: string;
  id: string;
  source_id: string;
}
export type ContentInteractionEvent = AnalyticsEventBase<
  typeof ContentInteractionEventName,
  ContentInteractionEventData
>;

export function createContentInteractionEvent(
  user: AnalyticsUser,
  type: string,
  id: string,
  source_id: string,
): ContentInteractionEvent {
  return {
    ...user,
    name: ContentInteractionEventName,
    data: {
      type,
      id,
      source_id,
    },
  };
}
