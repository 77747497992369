import React from 'react';
import ExperienceButton from '../ExperienceButton';
import Section from '../Section';

const OnlineMuseumSection = (): JSX.Element => (
  <Section
    colour="concrete"
    className="online-museum-section"
    contentClass="online-museum-section__content"
    centered
  >
    <p className="online-museum-section__text">
      <strong>A virtual space to learn about the Holocaust.</strong>
    </p>

    <ExperienceButton className="online-museum-section__button" />
  </Section>
);

export default OnlineMuseumSection;
