import React from 'react';
import VideoLookup from '../utilities/AssetLibrary';
import ZoneTemplate from './ZoneTemplate';

const Zone1 = (): JSX.Element => (
  <ZoneTemplate
    key="zone-1"
    className="zone-1"
    buildUrl="Build/Zone1"
    title="Introducing the Holocaust"
    videoUrl={VideoLookup['qhm-1s']}
    disclaimer={
      <>
        <p>
          This virtual environment does not seek to represent any building or structure in its true
          form. Instead, we have created a canvas to give you factual information about the events
          of the Holocaust.
        </p>
        <p>You may find this content distressing.</p>
      </>
    }
  >
    <p>
      Using historical sources and materials, we have created a virtual space for you to learn about
      the Holocaust.
    </p>
    <p>
      The Holocaust (or the ‘Shoah’) was the systematic persecution and murder of Jewish people in
      Europe by the Nazi German regime and their collaborators between 1933 and 1945. Jews were the
      primary targets and victims of genocide – an act committed with the intent to destroy a
      national, ethnic, racial or religious population group. The Nazis attempted to annihilate
      European Jewish people entirely – and almost succeeded. The Holocaust claimed the lives of
      approximately 6 million Jews, including 1.5 million children. There were 5 million other
      victims of the Nazis and their collaborators, including people with disabilities, Roma and
      Sinti communities, gay men, Polish people and Soviet prisoners of war (POWs), political
      opponents and Jehovah’s Witnesses.
    </p>
    <p>
      As you travel through this space, you will learn that the Holocaust required the participation
      and involvement of ordinary people. You will learn about the brave people who had the courage
      to save lives. You will hear the stories of Jewish people who survived, see digitised original
      artefacts and photographs, you will hear music and travel through time during the experience.
    </p>
    <p>
      We will ask questions to guide your thinking and understanding of the Holocaust. We also want
      you to reflect on any connections between this tragic period in history and what could be
      happening now, or in the future.
    </p>
    <p>
      We hope that from what you learn, you will find the courage to be an upstander &ndash; to not
      be indifferent and to stand up for persecuted or marginalised people. Before we do that, this
      zone will help you understand some of the historical conditions that made the Holocaust
      possible.
    </p>
    <p>
      <em>
        In this virtual space, there are primary sources, including survivor testimonies and digital
        copies of original artefacts and photographs. How do you think this will help you to
        understand the Holocaust?
      </em>
    </p>
  </ZoneTemplate>
);

export default Zone1;
