import React from 'react';
import VideoLookup from '../utilities/AssetLibrary';
import ZoneTemplate from './ZoneTemplate';

const Zone2A = (): JSX.Element => (
  <ZoneTemplate
    key="zone-2a"
    className="zone-2a"
    buildUrl="Build/Zone2A"
    title="The Holocaust between 1933 to 1939"
    videoUrl={VideoLookup['qhm-2as']}
    disclaimer={
      <>
        <p>
          This virtual environment does not seek to represent any building or structure in its true
          form. Instead, we have created a canvas to give you factual information about the events
          of the Holocaust.
        </p>
        <p>You may find this content distressing.</p>
      </>
    }
  >
    <p>
      In this zone of the virtual space, you will learn how the establishment of Nazi Germany marked
      the beginning of the Holocaust. To do so, you will make connections with what you have learned
      in this space so far.
    </p>
    <p>
      The Holocaust began in January 1933, when Adolf Hitler and the Nazi Party came to power in
      Germany. The Nazis replaced the democratic Weimar Republic and Germany became a dictatorship.
      Once in power, the Nazis made antisemitism their official government policy.
    </p>
    <p>
      Starting from the early years of the dictatorship (also known as the Third Reich), Jewish
      people in Germany – who were less than 1% of the population – faced increasing discrimination
      and persecution. The Nazi Government introduced hundreds of anti-Jewish restrictions and laws
      to legally remove German Jews from public, professional and economic life. The Nazis used
      propaganda to spread false and hateful narratives, creating a dangerous atmosphere for Jewish
      communities across Nazi Germany and Austria.
    </p>
    <p>
      During these 6 years, as part of the Nazis’ nationwide campaign against Jewish people, there
      were a series of boycotts and pogroms across Jewish people living in Germany and Austria. Nazi
      paramilitary groups, such as the SA and the SS, played a key role in implementing Nazi
      ideologies and policies through methods of intimidation and violence.
    </p>
    <p>
      Between 1933 and 1939, the Nazis introduced infrastructures to detain their victims –
      establishing the first concentration camp in Dachau in 1933.
    </p>
  </ZoneTemplate>
);

export default Zone2A;
