import { RefObject, useEffect } from 'react';
import { UnityEvent } from '../../defns/unity';

export default function (
  element: RefObject<HTMLElement | null>,
  handler: (event: CustomEvent<UnityEvent>) => void,
) {
  useEffect(() => {
    if (element.current) {
      element.current.addEventListener('unity-event', handler, false);
    }

    return () => {
      if (element.current) {
        element.current.removeEventListener('unity-event', handler);
      }
    };
  });
}
