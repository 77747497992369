import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Home from '../pages/Home';
import Zone1 from '../pages/Zone1';
import Zone2A from '../pages/Zone2A';
import Zone2B from '../pages/Zone2B';
import Zone3 from '../pages/Zone3';
import MobilePage from '../pages/MobilePage';

export const PathConstants = {
  Home: '/',
  Zone1: '/zone1',
  Zone2a: '/zone2a',
  Zone2b: '/zone2b',
  Zone3: '/zone3',
  Mobile: '/mobile',
  OnlineMuseumRef: 'online-museum',
  VisitRef: 'visit',
  AboutRef: 'about',
  PartnersRef: 'partners',
} as const;

export type AppProps = Record<string, never>;

const App: FunctionComponent<AppProps> = () => {
  const location = useLocation();

  if (isMobile && location.pathname !== PathConstants.Mobile) {
    return <Redirect to={PathConstants.Mobile} />;
  }

  return (
    <Switch>
      <Route path={PathConstants.Zone1} component={Zone1} />
      <Route path={PathConstants.Zone2a} component={Zone2A} />
      <Route path={PathConstants.Zone2b} component={Zone2B} />
      <Route path={PathConstants.Zone3} component={Zone3} />
      <Route path={PathConstants.Mobile} component={MobilePage} />
      <Route component={Home} />
    </Switch>
  );
};
export default App;
