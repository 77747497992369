import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import QldGovtImage from '../../assets/QldGovt.png';
import Section from '../Section';

const FooterSection = (): JSX.Element => (
  <Section colour="concrete" dark className="footer-section">
    <footer className="footer-section__content">
      <div className="footer-section__info footer-info">
        <p className="footer-info__name">
          <strong>Queensland Holocaust Museum And Education Centre Ltd</strong>
        </p>
        <p className="footer-info__abn">ABN 66 647 648 182</p>
      </div>
      <div className="footer-section__link-list footer-links">
        <a href="https://hmq.org.au/" className="footer-links__link">
          QHMEC Website
          <LaunchIcon className="footer-links__icon" fontSize="small" />
        </a>
        <a href="https://hmq.org.au/" className="footer-links__link">
          Sign up to keep updated
          <LaunchIcon className="footer-links__icon" fontSize="small" />
        </a>
        <a href="https://hmq.org.au/privacy-policy/" className="footer-links__link">
          Privacy Policy
          <LaunchIcon className="footer-links__icon" fontSize="small" />
        </a>
        <a href="https://hmq.org.au/terms-conditions/" className="footer-links__link">
          Terms & Conditions
          <LaunchIcon className="footer-links__icon" fontSize="small" />
        </a>
      </div>
      <div className="footer-section__support .footer-support">
        <img
          src={QldGovtImage}
          alt="Supported by the Queensland Government"
          className="footer-support__image"
        />
      </div>
    </footer>
  </Section>
);

export default FooterSection;
