import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import AnalyticsEventBase, { AnalyticsUser } from './AnalyticsEventBase';

export const TimespentEventName = 'TimeSpent';

export interface TimespentEventData {
  type: string;
  id: string;
  ms: number;
}
export type TimespentEvent = AnalyticsEventBase<typeof TimespentEventName, TimespentEventData>;

export function createTimespentEvent(
  user: AnalyticsUser,
  type: string,
  name: string,
  start: Date,
  end: Date,
): TimespentEvent {
  const millis = differenceInMilliseconds(end, start);

  return {
    ...user,
    name: TimespentEventName,
    data: {
      type,
      id: name,
      ms: millis,
    },
  };
}
