import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PathConstants } from '../containers/App';
import { CustomButtonProps } from './CustomButton';

export type ExperienceButtonProps = Omit<CustomButtonProps, 'children' | 'onClick'>;

const ExperienceButton = (props: ExperienceButtonProps): JSX.Element => {
  const { className } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(PathConstants.Zone1);
  };

  return (
    <Link className={['custom-button', className].join(' ')} to={PathConstants.Zone1}>
      Launch online museum
    </Link>
  );
};

export default ExperienceButton;
