import { makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface LoadScreenProps {
  title: string;
  progress: number;
  disclaimer?: ReactNode | ReactNode[];
  onClick: () => void;
  children: ReactNode | ReactNode[];
}

const useStyles = makeStyles({
  progressInner: ({ progress }: { progress: number }) => ({
    width: `${progress * 100}%`,
  }),
});

const LoadScreen = (props: LoadScreenProps): JSX.Element => {
  const { title, progress, disclaimer, onClick, children } = props;

  const styles = useStyles({ progress });

  return (
    <button className="loading-screen" type="button" onClick={onClick}>
      <h1 className="loading-screen__title">{title}</h1>

      <div className="loading-screen__content">{children}</div>
      {disclaimer && <div className="loading-screen__disclaimer">{disclaimer}</div>}

      {progress === 1 ? (
        <h3 className="loading-screen__ready-message">Press any button to continue</h3>
      ) : (
        <div className="loading-screen__bar-outer">
          <div className={['loading-screen__bar-inner', styles.progressInner].join(' ')} />
          <span className="loading-screen__bar-text">{(progress * 100).toFixed(0)}%</span>
        </div>
      )}
    </button>
  );
};

export default LoadScreen;
