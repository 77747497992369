import React from 'react';
import ExperienceButton from '../ExperienceButton';
import LogoImage from '../LogoImage';
import Section from '../Section';

const HeaderSection = (): JSX.Element => (
  <Section className="header-section" contentClass="header-section__content">
    <div className="header-section__group">
      <LogoImage className="header-section__logo" />
      <ExperienceButton className="header-section__button" />
    </div>
    <h2 className="header-section__subtitle">
      Remember.
      <br />
      Never. Again.
    </h2>
  </Section>
);

export default HeaderSection;
