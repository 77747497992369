import { Box, Modal } from '@material-ui/core';
import React, { forwardRef } from 'react';

export interface VideoModalProps {
  src: string | false | null;
  onClose?: () => void;
}

const VideoModal = forwardRef<HTMLVideoElement, VideoModalProps>((props, ref) => {
  const { src, onClose } = props;

  return (
    <Modal open={src !== null} onClose={onClose}>
      <div className="video-modal">
        {src ? (
          <video ref={ref} className="video-modal__player" autoPlay controls>
            <source src={src} />
          </video>
        ) : (
          <Box component="p" sx={{ color: '#FFFFFF' }}>
            Error: Video not found
          </Box>
        )}
      </div>
    </Modal>
  );
});

export default VideoModal;
