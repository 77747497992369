import React from 'react';
import Section from '../Section';

const CopyrightSection = (): JSX.Element => (
  <Section
    colour="concrete"
    centered
    className="copyright-section"
    contentClass="copyright-section__content"
  >
    <p className="copyright-section__text">
      <small>All rights reserved 2023 @ Queensland Holocaust Museum and Education Centre Ltd</small>
    </p>
  </Section>
);

export default CopyrightSection;
