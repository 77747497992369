import React, { forwardRef, useEffect, useRef } from 'react';
import { UnityInstance } from '../defns/unity';

export interface UnityPlayerProps {
  buildUrl: string;
  onLoadProgress?: (progress: number) => void;
  onLoadComplete?: (instance: UnityInstance) => void;
}

const UnityPlayer = forwardRef<HTMLCanvasElement, UnityPlayerProps>((props, ref): JSX.Element => {
  const { buildUrl, onLoadProgress, onLoadComplete } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Load the unity experience
  useEffect(() => {
    const loaderUrl = `${buildUrl}.loader.js`;

    const config = {
      dataUrl: `${buildUrl}.data`,
      frameworkUrl: `/${buildUrl}.framework.js`,
      codeUrl: `${buildUrl}.wasm`,
      streamingAssetsUrl: 'StreamingAssets',
      companyName: 'SpacesInteractive',
      productName: 'T.H.E.',
      productVersion: '0.1',
    };

    const canvas = document.getElementById('unity-canvas');

    const script = document.createElement('script');
    script.src = `/${loaderUrl}`;

    script.onload = () => {
      createUnityInstance(canvas, config, onLoadProgress)
        .then((instance) => {
          console.log('Unity load complete');
          if (onLoadProgress) {
            onLoadProgress(1);
          }
          if (onLoadComplete) {
            onLoadComplete(instance);
          }
        })
        .catch((err: any) => {
          console.log('Unity load failed', err);
        });
    };
    document.body.appendChild(script);
  }, []);

  // Dynamically update canvas size
  useEffect(() => {
    const scaleCanvas = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const rect = canvas.getBoundingClientRect();
        canvas.width = rect.width * window.devicePixelRatio;
        canvas.height = rect.height * window.devicePixelRatio;
      }
    };

    scaleCanvas();
    window.addEventListener('resize', scaleCanvas);

    return () => window.removeEventListener('resize', scaleCanvas);
  });

  // Update the forwarded ref
  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(canvasRef.current);
      } else {
        ref.current = canvasRef.current;
      }
    }
  });

  return <canvas ref={canvasRef} id="unity-canvas" className="unity-player__canvas" />;
});

export default UnityPlayer;
