import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AnalyticsEvent } from './events/AnalyticsEventBase';
import useSessionId from './useSessionId';

export default function useUnauthenticatedUser(): Pick<AnalyticsEvent, 'session_id' | 'user_id'> {
  let user_id = localStorage.getItem('user_id');
  if (!user_id) {
    user_id = uuidv4();
    localStorage.setItem('user_id', user_id);
  }

  const session_id = useSessionId();

  return useMemo(
    () => ({
      user_id,
      session_id,
    }),
    [user_id, session_id],
  );
}
