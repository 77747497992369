import React, { useContext, useEffect, useRef, useState } from 'react';
import LoadScreen, { LoadScreenProps } from '../components/LoadScreen';
import UnityPlayer, { UnityPlayerProps } from '../components/UnityPlayer';
import { UnityInstance } from '../defns/unity';
import useAnalyticsTimer from '../plugins/analytics/useAnalyticsTimer';
import { VideoContext } from '../utilities/contexts/VideoContextProvider';
import useQHMUnityEvents from '../utilities/hooks/useQHMUnityEvents';

export interface ZoneTemplateProps
  extends Omit<LoadScreenProps, 'progress' | 'onClick'>,
    Pick<UnityPlayerProps, 'buildUrl'>,
    Partial<Pick<UnityPlayerProps, 'onLoadProgress'>> {
  videoUrl?: string;
  className: string;
  onStart?: () => void;
}

const ZoneTemplate = (props: ZoneTemplateProps): JSX.Element => {
  const { buildUrl, videoUrl, className, children, onLoadProgress, onStart, ...loadScreenProps } =
    props;

  const unityRef = useRef<HTMLCanvasElement>(null);
  const { src } = useContext(VideoContext);
  useAnalyticsTimer('Page', className ?? null);
  const loadTimer = useAnalyticsTimer('Load', className ?? null);
  const zoneTimer = useAnalyticsTimer('Zone', className ?? null);

  useQHMUnityEvents(unityRef, className);

  const [unityInstance, setUnityInstance] = useState<UnityInstance>();

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showLoadScreen, setShowLoadScreen] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleLoadProgress = (value: number) => {
    setLoadingProgress(value);
  };

  const handleLoadComplete = (instance: UnityInstance) => {
    setUnityInstance(instance);
    loadTimer.StopAndSend();
    zoneTimer.Restart();
  };

  const closeLoadingScreen = () => {
    if (loadingProgress === 1) {
      setShowLoadScreen(false);
      if (onStart) {
        onStart();
      }
    }
  };

  // Listen for any button press
  useEffect(() => {
    if (loadingProgress === 1 && showLoadScreen) {
      window.addEventListener('keypress', closeLoadingScreen);
    }
    return () => {
      if (loadingProgress === 1 && showLoadScreen) {
        window.removeEventListener('keypress', closeLoadingScreen);
      }
    };
  });

  useEffect(
    () => () => {
      console.log('Leaving page ', className);
      zoneTimer.StopAndSend();
    },
    [],
  );

  useEffect(() => {
    if (src) {
      unityInstance?.SendMessage('WebCommunicationService', 'OnPopupOpen');
    } else {
      unityInstance?.SendMessage('WebCommunicationService', 'OnPopupClose');
    }
  }, [src, unityInstance]);

  useEffect(
    () => () => {
      if (unityInstance) {
        console.log('Closing previous unity instance');
        unityInstance.Quit().then(() => {
          console.log('Previous Unity instance closed');
        });
      }
    },
    [unityInstance],
  );

  return (
    <div className={[className, 'unity-player--fullscreen'].join(' ')}>
      <UnityPlayer
        ref={unityRef}
        buildUrl={buildUrl}
        onLoadProgress={handleLoadProgress}
        onLoadComplete={handleLoadComplete}
      />

      {showLoadScreen && (
        <LoadScreen {...loadScreenProps} progress={loadingProgress} onClick={closeLoadingScreen}>
          {videoUrl && (
            <div className="loading-screen__video">
              <video ref={videoRef} autoPlay controls>
                <source src={videoUrl} />
              </video>
            </div>
          )}

          {children}
        </LoadScreen>
      )}
    </div>
  );
};

export default ZoneTemplate;
