import { useMutation } from '@tanstack/react-query';
import { AnalyticsEvent } from './events/AnalyticsEventBase';

const api = process.env.REACT_APP_DASHBOARD_URL;
const slug = process.env.REACT_APP_DASHBOARD_SLUG;

export default function useAnalyticsDispatch() {
  return useMutation({
    mutationKey: ['/analytics'],
    mutationFn: (e: AnalyticsEvent) =>
      fetch(`${api}/project/${slug}/analytics`, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json',
        }),
        body: JSON.stringify(e),
      }),
  });
}
