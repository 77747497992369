import React from 'react';
import VideoLookup from '../utilities/AssetLibrary';
import ZoneTemplate from './ZoneTemplate';

const Zone2B = (): JSX.Element => (
  <ZoneTemplate
    key="zone-2b"
    className="zone-2b"
    buildUrl="Build/Zone2B"
    title="The Holocaust during WW2"
    videoUrl={VideoLookup['qhm-2bs']}
    disclaimer={
      <>
        <p>
          This virtual environment does not seek to represent any building or structure in its true
          form. Instead, we have created a canvas to give you factual information about the events
          of the Holocaust.
        </p>
        <p>You may find this content distressing.</p>
      </>
    }
  >
    <p>
      American historian Lucy S. Dawidowicz says that Germany fought 2 wars simultaneously &ndash;
      WW2 against the Allies and the racial war against the Jews.
    </p>
    <p>
      The height of the persecution and murder of Europe’s Jewish population happened during WW2. In
      this zone of the virtual space you will learn how during WW2, from 1939 to 1945, the Holocaust
      was implemented through a combination of atrocious living conditions, strategic starvation,
      mass shootings and gassing. The Nazis increasingly relied on the SS as they rapidly expanded
      their camp system beyond imprisonment towards forced labour and mass murder.
    </p>
    <p>
      As part of their 'Final Solution of the Jewish Question' (
      <em>die Endlösung der Judenfrage</em>) the Nazis used their networks of forced labour,
      concentration and extermination (death) camps, and confinement sites, such as ghettos.
    </p>
    <p>
      As the Nazis and their mobile killing squads (Einsatzgruppen) invaded countries across Europe
      and the Soviet Union, they collaborated with local authorities, and received support from
      local citizens, to locate and arrest Jewish people, confiscate their homes and businesses, and
      to organise their deportation to ghettos and camps. The Nazis encountered millions of Jewish
      people who lived in the territories they had invaded as part of their war campaign. Many
      people stood by and watched as Jews from their neighbourhoods were rounded up to be shot or
      transported to killing centres. But while there was widespread indifference in Europe to the
      fate of Jewish communities, you will learn that there were some ordinary people who had the
      courage to care about the people who were persecuted by the Nazis and their collaborators.
    </p>
  </ZoneTemplate>
);

export default Zone2B;
