import React from 'react';
import CopyrightSection from '../components/sections/CopyrightSection';
import FooterSection from '../components/sections/FooterSection';
import HeaderSection from '../components/sections/HeaderSection';
import OnlineMuseumSection from '../components/sections/OnlineMuseumSection';
import useAnalyticsTimer from '../plugins/analytics/useAnalyticsTimer';

const Home = (): JSX.Element => {
  useAnalyticsTimer('Page', 'home');

  return (
    <div>
      <HeaderSection />
      <OnlineMuseumSection />
      <FooterSection />
      <CopyrightSection />
    </div>
  );
};

export default Home;
